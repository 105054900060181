@import "variables";
@import "auth";

.breadcrumb {
  margin-top: 10px;
}

.form_label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
}

/* /****** DAY PICKER /******** */

.DayPicker-Caption > div {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.DayPicker-Weekday abbr[title] {
  font-size: 18px !important;
  padding: 8px !important;
}

.DayPicker-Day {
  font-size: 18px !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #c55b25 !important;
}

.DayPicker-NavButton {
  width: 2em;
  height: 2em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.tableFixHead {
  overflow: auto !important;
  /*  height: 100vh !important; */
}
.tableFixHead thead th {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.btn-anexos {
  text-transform: uppercase;
  background-color: white;
  color: #1cbfd0;
  border: 2px solid #1cbfd0;
  &:hover {
    color: #1cbfd0;
  }
}

.rowEditing {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 0, 0.2) !important;
  transition: 0.3s;
}
.rowInactive {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.rowAnswered {
  background-color: rgba(50, 205, 50, 0.2) !important;
}

.input-group-prepend {
  cursor: pointer;
}

.buttonSearcher {
  background: transparent;
  border: 2px solid rgba(197, 91, 37, 0.4);
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
}

.dropdownItem {
  cursor: pointer;
  &:hover {
    background: rgba(197, 91, 37, 0.2);
  }
}

.notRisk {
  background-color: rgba(210, 215, 211, 0.8) !important;
}

.minim {
  background-color: rgba(135, 206, 250, 0.6) !important;
}
.low {
  background-color: rgba(50, 205, 50, 0.5) !important;
}
.medium {
  background-color: rgba(255, 204, 0, 0.5) !important;
}
.high {
  background-color: rgba(255, 160, 122, 0.9) !important;
}
.outRange {
  background-color: rgba(255, 41, 46, 0.76) !important;
}

.notAnswered {
  background-color: rgba(8, 23, 37, 0.6) !important;
}
.total {
  background-color: rgb(33, 123, 226) !important;
}
