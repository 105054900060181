.text {
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.cardnumber {
  font-weight: 700;
  font-size: 35px;
}
