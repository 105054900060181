.r_mega_menu {
  background: white;
  border: 0px;
  font-weight: 900;
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background: #c55b25;
    color: white;
    border-radius: 5px;
  }
}
.r_mega_menu_icon {
  font-weight: 500 !important;
  font-size: 20px !important;
}
