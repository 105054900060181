.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.tags li {
  float: left;
  cursor: pointer;
}

.tag {
  background: rgba(46, 204, 113, 0.8);
  border-radius: 3px 0 0 3px;
  color: white;
  font-weight: 600;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::after {
  background: #f5f5f5;
  border-bottom: 13px solid transparent;
  border-left: 10px solid rgba(46, 204, 113, 0.8);
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: crimson;
}

.tag:hover::after {
  border-left-color: crimson;
}

.icon {
  cursor: pointer;
  font-size: 16px;
  padding-left: 10px;
  font-weight: 700;
}
