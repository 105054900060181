.role {
  text-transform: lowercase;
  /* text-transform: capitalize; */
}

.navbar_item_active {
  color: #c55b25 !important;

  border-right: 2px solid #c55b25;
}

.sidebar .menu .list .scrollable{
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.collapseTitle {
  cursor: pointer;
}

.collapseList {
  list-style-type: none;
  padding-left: 20px;
}
