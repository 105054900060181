.r-auth-description {
  margin-top: 25px;
  margin-bottom: 0;
}

.forgot {
  background-color: white;
  border: 0px;
  font-size: 10px;
  padding: 0;
  color: #c55b25;
  cursor: pointer;
}

.btn-rigori {
  background: #c55b25;
  &:hover {
    color: white;
  }
}
