.pointer {
  cursor: pointer;
}

.textCapitalize{
  text-transform: capitalize;
}

.text {
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.cardnumber {
  font-weight: 700;
  font-size: 18px;
}

.porcentage {
  font-weight: 800;
  font-size: 30px;
}

.notRisk {
  background-color: rgba(210, 215, 211, 0.8) !important;
  color: white !important;
}

.minim {
  background-color: rgba(135, 206, 250, 0.6) !important;
  color: white !important;
}
.low {
  background-color: rgba(50, 205, 50, 0.5) !important;
  color: white !important;
}
.medium {
  background-color: rgba(255, 204, 0, 0.5) !important;
  color: white !important;
}
.high {
  background-color: rgba(255, 160, 122, 0.9) !important;
  color: white !important;
}
.outRange {
  background-color: rgba(255, 41, 46, 0.76) !important;
  color: white !important;
}

.notAnswered {
  background-color: rgba(8, 23, 37, 0.6) !important;
  color: white !important;
}
.total {
  background-color: rgb(33, 123, 226) !important;
  color: white !important;
}
